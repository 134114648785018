
	import { Component, Provide, Vue } from 'vue-property-decorator';
	import AppGlobals from '@/components/AppGlobals.vue';
	@Component({
		name: 'Empty',
		components: { AppGlobals },
	})
	export default class Empty extends Vue {
		@Provide()
		handleScrollBack(isSmoothBehavior = false, offsetTop = 0) {
			if (process.client && this.$refs?.refMainLayout) {
				const $el = this.$refs.refMainLayout as HTMLElement;
				const behavior = (isSmoothBehavior ? 'smooth' : 'instant') as any;
				$el.scrollTo({ top: offsetTop || 0, behavior });
			}
		}
	}
